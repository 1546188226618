import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout'; // 1: 超级管理员
// 2：渠道用户
// 3: 分销用户

var isAllRs = [1, 2, 3, 4, 50];
var isAmdinRs = [1, 2, 3, 50];
var isUserRs = [1, 2, 3, 50];
var distribution = [2, 50];
var content = [10, 50]; // const isAllRs = [101, 102, 103, 104];
// const isAmdinRs = [101,102,103];
// const isUserRs = [101,102];

var userRoleType = window.sessionStorage.getItem('userRoleType');
console.log('userRoleType====', userRoleType);
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/shortplay',
  component: Layout,
  meta: {
    title: '作品管理',
    icon: 'form',
    roles: content
  },
  redirect: '/shortplay/list',
  children: [{
    path: 'list',
    name: 'shortplay-list',
    component: function component() {
      return import('@/views/shortplay/list');
    },
    meta: {
      title: '作品列表',
      icon: 'form',
      roles: content
    }
  }]
}, {
  path: '/actors',
  component: Layout,
  meta: {
    title: '演员列表',
    icon: 'form',
    roles: content
  },
  redirect: '/actors/list',
  children: [{
    path: 'list',
    name: 'actors-list',
    component: function component() {
      return import('@/views/actors/list');
    },
    meta: {
      title: '演员列表',
      icon: 'form',
      roles: content
    }
  }]
} // {
// 	path: '/ranking',
// 	component: Layout,
// 	meta: {
// 		title: '排行榜列表',
// 		icon: 'form',
// 		roles: content
// 	},
// 	redirect: '/ranking/list',
// 	children: [{
// 			path: 'list',
// 			name: 'shortplay-list',
// 			component: () => import('@/views/ranking/list'),
// 			meta: {
// 				title: '排行榜列表',
// 				icon: 'form',
// 				roles: content
// 			}
// 		},
// 	]
// },
]; // 解决重复点击导航路由报错

var originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;