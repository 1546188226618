import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/home/wwwroot/calendar-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Layout from '@/layout';
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from "@/assets/styles/variables.scss"; // const adminRoutes = [];
// const setRoutes = {
//   user: ['/','/user', '/shortplay', '/meal', '/order', '/task'],
//   channel: ['/channel']
// }

export default {
  name: "Sidebar",
  components: {
    Logo: Logo,
    SidebarItem: SidebarItem
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["sidebar", "rule"])), {}, {
    routes: function routes() {
      var routes = this.$router.options.routes;
      console.log(routes);
      var resRoutes = [];
      var distributeType = localStorage.getItem('distributeType');
      var userRoleType = window.sessionStorage.getItem('userRoleType');
      routes.forEach(function (item) {
        // 动态渲染菜单,权限101为全部开启
        item.hideBtn = false;

        if (userRoleType != 1) {
          if (distributeType == 1) {
            if (item.meta.title == '小说管理') {
              item.hideBtn = true;
            }
          } else if (distributeType == 2) {
            if (item.meta.title == '短剧管理') {
              item.hideBtn = true;
            }
          }
        }

        resRoutes.push(item);
      });
      console.log('resRoutes====', resRoutes);
      return resRoutes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    variables: function variables() {
      return _variables;
    }
  })
};