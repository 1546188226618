var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "navbar" },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: { "is-active": _vm.sidebar.opened },
            on: { toggleClick: _vm.toggleSideBar }
          }),
          _c("breadcrumb", { staticClass: "breadcrumb-container" }),
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _vm.hideBtn == false
                ? _c(
                    "div",
                    { staticClass: "other-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.clickBtn }
                        },
                        [_vm._v("API文档")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container",
                  attrs: { trigger: "click" }
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _vm.userRoleType == "2"
                      ? _c("span", { staticClass: "user-name" }, [
                          _vm._v("分销平台：" + _vm._s(_vm.name))
                        ])
                      : _vm._e(),
                    _vm.userRoleType == "3"
                      ? _c("span", { staticClass: "user-name" }, [
                          _vm._v("投手平台：" + _vm._s(_vm.name))
                        ])
                      : _vm._e(),
                    _vm.userRoleType == "10"
                      ? _c("span", { staticClass: "user-name" }, [
                          _vm._v("内容平台：" + _vm._s(_vm.name))
                        ])
                      : _vm._e(),
                    _vm.userRoleType == "50"
                      ? _c("span", { staticClass: "user-name" }, [
                          _vm._v("作者平台：" + _vm._s(_vm.name))
                        ])
                      : _vm._e(),
                    _c("i", { staticClass: "el-icon-caret-bottom" })
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "user-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.logout }
                          },
                          [_vm._v("退出登录")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "API文档",
            visible: _vm.dialogMy,
            fullscreen: false,
            "close-on-click-modal": false,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogMy = $event
            },
            close: function($event) {
              _vm.dialogMy = false
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "data-form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.formItem, "label-width": "120px" }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "_w300",
                      staticStyle: { "margin-bottom": "0px" },
                      attrs: { label: "apiAccountId:" }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.formItem.apiAccountId))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "_w300",
                      staticStyle: { "margin-bottom": "0px" },
                      attrs: { label: "salt:" }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.formItem.salt))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "_w300",
                      staticStyle: { "margin-bottom": "0px" },
                      attrs: { label: "API:" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#409EFF" },
                          attrs: {
                            target: "_blank",
                            href:
                              "https://aaao3zg5f2d.feishu.cn/docx/N50Mdi70sooV9gxe4gBcbJOPnKc"
                          }
                        },
                        [_vm._v("接口对接文档")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogMy = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }